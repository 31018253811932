<template>
	<div class="Home">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12 mb-6>
				<h2 class="indigo--text text--darken-4">Bem-vindo, {{nome}}!</h2>
				<p>Utilize o menu principal para acessar as áreas que deseja.</p>
			</v-flex>

			<v-flex xs12>
				<h2 class="indigo--text text--darken-4 mb-2">Aniversariantes do mês</h2>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.hoje="{ item }">
						<v-chip class="ma-2 white--text text--white" color="success" v-if="item.hoje == '1'">É hoje!!! <v-icon>mdi-party-popper</v-icon></v-chip>
					</template>
				</v-data-table>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	data() {
		return {
			nome: localStorage.getItem('nome_usuario'),
			data: {
				listagemGeral: [{nome: 'Buscando...'}]
			}
		}
	},
	methods: {
		buscaAniversariantesDoMes() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'colaboradores/listagem_aniversatiantes_do_mes',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		listagemHeaders() {
			return [
				{ text: 'Nome', value: 'nome', class: 'blue darken-4 white--text' },
				{ text: '', value: 'hoje', class: 'blue darken-4 white--text' },
				{ text: 'Data Nascimento', value: 'data_nascimento_formated', class: 'blue darken-4 white--text' },
				{ text: 'Função', value: 'desc_cargo_colaborador', class: 'blue darken-4 white--text' }
			];
		}
	},
	mounted() {
		this.buscaAniversariantesDoMes();
	}
};
</script>
